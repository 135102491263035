import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
import PayImage from '../images/solutions/pay.jpg'
import PulseImage from '../images/solutions/pulse.jpg'
import SmileyImage from '../images/solutions/smiley.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

const FeaturedProjects = () => {
    return (
        <Layout pageTitle="PragICTS | Cyber Security Operations (CyberSecOps) | Featured Projects">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>FEATURED <br/>  <span> PROJECTS</span></h2>
                    
                                <div className="horizonral-subtitle"><span>PROJECTS</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>PROJECTS</div>
                        <div className="container main-about">
                        <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Security Optimzation Through Refactoring Of Wireless Access Points (WAP) And Switches <br/> 05/2025</h4>
                                    <p>Replacement of WAP with configurable CISCO and Extreame Networks enterprise grade WAPs, switches, etc. with configuration for performance and granular security.</p>
                                   
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Migration of On-Premise Legacy Applications (Including ERP) To Cloud<br/> 05/2024</h4>
                                    <p>Migration of ERP and related software platforms from on-premise blade servers to Micrsoft Azure Based Cloud Infrastructure with security tightening to the cloud infrastructure through dedicated tunnelling from the Cloud to the on-premise Firewall for restricted access. ERP applications can only be effectively accessed within the office infrastructure with through the office infrastructure with designated computing devices only.</p>
                                   
                                </div>
                            </div>
                          
                        </div>
                    </div>    
                    <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Enterprise Grade CheckPoint Firewall Implementation And Tightening Of Security At Granular Level<br/>02/2024</h4>
                                    <p>Design, Implementation, Management and Support of Enterprise Grade CheckPoint Firewall to one of the top  Destination Management Companies in Sri Lanka</p>
                                   
                                </div>
                            </div>
                          
                        </div>
                    </div>     
                    <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Enterprise Grade Palo Alto Firewall Implementation And Tightening Of Security At Granular Level<br/>01/2024</h4>
                                    <p>Replacement of enterprise grade Fortinet firewalls with single Palo Alto firewall with advance features.</p>
                                   
                                </div>
                            </div>
                          
                        </div>
                    </div>     
                    <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Redesign and Implementation of Entire Enterprise Network Infrastructure From Ground Up<br/>12/2023</h4>
                                    <p>Redesign and implementation of IT network infrastructure with CAT 6e cabelling, Firber cabelling at floor level with Extreame Network Switches, CISCO WAS and migration to Windows Server 2022 infrastructure.</p>
                                   
                                </div>
                            </div>
                          
                        </div>
                    </div>    







                        </div>
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
              

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default FeaturedProjects
